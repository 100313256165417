<template>
  <div>
    <FullScreenDialog
      v-model="dialog"
      without-padding
      title="Prodotti"
      :route-father="routeFather"
    >
      <template v-slot:header-actions>
        <ExportExcelTable
          :worksheetName="worksheetName"
          :fileName="fileName"
          :fieldsToExport="fieldsToExport"
          :fetch="fetchExcel"
        ></ExportExcelTable>
        <v-btn text link @click="openItemCreateDialog = true" :disabled="isLicenseBlocked">
          <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
        </v-btn>
      </template>
      <template v-slot:content>
        <div class="d-flex" style="width: 100%">
          <ItemsTable
            table-height="calc(100dvh - 270px)"
            @item-detail="detailItem"
            @item-double-clicked="detailItem"
            :filters.sync="filtersValue"
            :bus="bus"
          ></ItemsTable>

          <ItemCreateDialog
            v-if="openItemCreateDialog"
            :open-dialog="openItemCreateDialog"
            @close="close"
          >
          </ItemCreateDialog>
        </div>
      </template>
    </FullScreenDialog>
  </div>
</template>

<script>
import itemService from "@/services/warehouse/items.service.js";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import ItemCreateDialog from "@/components/areas/warehouse/items/new/ItemCreateDialog.vue";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import DepartmentsAutocomplete from '@/components/common/DepartmentsAutocomplete.vue'
import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import Vue from 'vue'

import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import ItemsTable from "@/components/common/wharehouse/ItemsTable.vue";
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "ItemList",
  components: {
    ExportExcelTable,
    FullScreenDialog,
    AdvancedFilter,
    ItemCreateDialog,
    SuppliersAutocomplete,
    TypeDataTable,
    DepartmentsAutocomplete,
    TagsAutocomplete,
    ManualBarcodeInput,
    ItemsTable
  },
  data: function () {
    let props = this.$router.resolve({ name: "Warehouse" });

    return {
      openItemCreateDialog: false,
      dialog: true,
      loading: false,
      items: [],
      routeFather: props.href,
      itemToDelete: undefined,
      bus: new Vue(),
      fieldsToExport: {},
      worksheetName: "",
      fileName: "",
      filtersValue: [],

      barcodePermission: undefined,
      showBarcode: false,
      isLicenseBlocked: undefined
    };
  },
  mixins:[dateUtils],
  props: {
    filters: {
      type: Array,
      default: undefined,
    },
  },
  mounted: function () {
    this.filtersValue = this.filters;
    this.fieldsToExport = itemService._fieldsToExport();
    this.worksheetName = "Lista Prodotti";
    this.fileName =
      "Lista_Prodotti_" + this._buildDateToExport(new Date()) +
      ".xls";
  },
  methods: {
    close(newItem) {
      if (newItem) this.bus.$emit('reload-items');
      this.openItemCreateDialog = false;
    },
    async fetchExcel(){
      let res = await itemService.list(undefined, 15000, this.filtersValue)
      return res.rows
    },
    detailItem(item) {
      this.$router.push({
        name: "ItemHome",
        params: {
          id: item.id,
          filters: this.filtersValue,
        },
        query: {
          pathToGoBack: this.$router.resolve({ name: "ItemList" }).href,
          pathName: 'ItemList',
        },
      });
    },
  },
  computed: {
    filteredItems() {
      return this.items;
    },
    titleDelete() {
      if (this.itemToDelete)
        return "Elimina " + this.itemToDelete.code
      else 
        return "Elimina Prodotto"
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
</style>